import React from "react";
import Footer from '../components/footer';
import Banner from '../content/banner';

const content = () => {
  return (
  <div>
    <div className="project-detail">

      <div className="project-detail-title container mt-xl">
        <h1 className="project-title">
          Companion App.<br></br><span>Stay safe.</span>
        </h1>
      </div>


      <div className="project-detail-content">

        <div className="bg-soft-grey text-center displace-xl mb-m">
          <img src={`${process.env.PUBLIC_URL}/img/projects/companion/companion-hero-devices.png`} className="text-center" alt="Companion App"/>
        </div>

        <div className="container container-xs mt-l">
          <div className="label">The impact</div>
          <p className="text-bold">
            Redesigned the Companion app with progressive disclosure, simplifying user flows, clarifying key features, and enhancing trust, leading to a more intuitive experience and increased feature engagement.
          </p>
        </div>

        <div className="container container-xs mt-l">
        <div className="label">THE CONTEXT</div>
        <p className="text-bold">
          Back in April, while I was in a small trip to Berlin, I received an email from Jake Wayne & Lexie Ernst, both CO-CEO of Companion App, they were asking for a redesign of their current app.
        </p>
        <p>
          I had enough experience designing digital products, and also worked a lot with maps -I love maps-, and that’s exactly how Jake and Lexie found me.
        </p>
        <p>
          Typically I would’ve stuck to the plan, and would not accepted extra work, but I felt connected with the idea, and simply decided to at least have a chat. After some emails from bars and coworking spaces in Berlin, I came back home and decided to send a quick proposal redesigning the landing page. Nothing too deep, just an "alignment test" something quick to show my design vision and thought process. 
        </p>
      </div>


      <div className="fullwidth mt-l text-center pt-s pb-s bg-soft-grey">
        <div className="container container-xs">
          <img src={`${process.env.PUBLIC_URL}/img/projects/companion/home-full.jpg`} alt="Companion New App" className="has-shadow" />
          <div className="caption">Quick proposal redesigning the landing page, edited with final screens.</div>
        </div>
      </div>


      <div className="container container-xs mt-l">
        <p>After sharing it we had a chat, and we were very aligned, and started to work in scheduled sprints. At this time I was thinking that it was probably my first time working for a startup in the States, and Hey! It was cool!</p>
      </div>

      <div className="container container-xs mt-l">
        <div className="label">Goals of the redesign</div>
        <p className="text-bold">
          According to Jake & Lexie, there were several objectives for the mobile app redesign.
        </p>
        <p>
          Companion lets you reach out to family, friends, or your public safety department to have them keep an eye on you as you travel late at night.
          So helping users in the process of selecting a <strong>Mode of Transportation</strong> in an easy way was the main goal. We want to make it easier for people to browse the app, navigate from feature to feature with as little friction as possible. We also wanted to drive usage of important features, like <strong>‘Wander Mode’</strong>, that weren’t being utilized as much as we want them to be.
        </p>
        <p>
          Companion is a complex app, a lot happens between the start and end of a trip. I was called to explore creatively and design focusing on the first part.
          <br></br>
        </p>
        <img src={`${process.env.PUBLIC_URL}/img/projects/companion/how-it-works.jpg`} alt="How it works" />
        <div className="caption">How Companion works, and the design challenge focus area.</div>
      </div>



      <div className="container container-xs mt-l">
        <div className="label">INSIGHTS</div>
        <p className="text-bold">
          I helped identify the needs and pain points of companion users.
        </p>
        <p>
          Once I have a good idea of the general feature set, my next step is to create a point of view and try to <strong>understand the problem</strong>, and If I’m going to solve a problem, I want sufficient information to solve it. I also want to work from a position of being informed and confident. So, I tested the app, had some discussions with Jake and Lexie, and wrote tons of notes from user flows to UI decisions.
        </p>
        <img src={`${process.env.PUBLIC_URL}/img/projects/companion/persona.jpg`} alt="How it works" />
        <div className="caption">User needs help to detect problems</div>
        <br></br>
        <img src={`${process.env.PUBLIC_URL}/img/projects/companion/transportation-mode-completion.jpg`} alt="Canvas Proposal" />
        <div className="caption">I sketched out our users ideal flow for selecting transportation, simply by completing a trip.</div>
        <br></br>
        <p>
          I also conducted a survey with a set of questions related to tasks. Surveys are a handy way to learn about customers. I started simple with clear goals about what we wanted to learn, and had both quantitative (measurable) and qualitative (how a customer feels) data, both provided a lot of insight to learn about task success, and to weigh the pros and cons of each approach.
        </p>
        <img src={`${process.env.PUBLIC_URL}/img/projects/companion/survey.jpg`} alt="Survey results" />
        <div className="caption">Survey results provided lots of insight.</div>
        <p>
          One of the primary issues that popped out was that people easily get confused during the process of changing between transportation modes during a trip, due to lack of understanding of interaction patterns in our current product.
        </p>
      </div>

      <div className="container container-xs mt-l">
        <div className="label">DISCOVERY</div>
        <p className="text-bold">
          In the light of our insights my task was to lead design exploration towards a light and trustful experience.
        </p>
        <p>
          Based on insights, we reshaped our transportation selection, to better engage our users through their trip journey.
        </p>
        <img src={`${process.env.PUBLIC_URL}/img/projects/companion/canvas.png`} alt="Canvas Proposal" />
        <div className="caption">Work in progress. Different approaches testing user flows and navigation patterns.</div>
        <br></br>
        <p>
          Then concepting began. I developed three different versions of the app to test – one with a conversational approach (Option A), one with more options (Option B), and one very minimal options (Option C).
          I tested these concepts to get perspective and scope for each version.
        </p>
      </div>

      <div className="fullwidth mt-l text-center pt-s pb-s bg-soft-grey">
        <div className="container-xl auto">
          <img src={`${process.env.PUBLIC_URL}/img/projects/companion/proposals.png`} alt="Proposals" />
          <div className="caption">Three original prototype options (A, B, and C from left to right) for the Companion app redesign</div>
        </div>
      </div>

      <div className="container container-xs mt-l">
        <div className="label">The Proposal</div>
        <p className="text-bold">
          Redesigning UI, User flows and Interaction patterns.
        </p>
        <p>
          Based on reviews, Option C version was identified as the winner. I then refined interactions, navigation, and visual design of this prototype, and made sure everything would work consistently across iOS and Android devices.
        </p>
        <img className="bg-soft-grey" src={`${process.env.PUBLIC_URL}/img/projects/companion/colors&map.png`} alt="Map and Colors" />
        <div className="caption">Refreshed colour palette & map style for Companion.</div>
        <p>
          Main proposal was to unify transportation bar into a single descriptive and always-visible icon. The icon needs to be tapped to show all the different modes. User research gave us the clue that Walk Mode can be default option. Making transportation modes a list is also an scalable approach, we can add more modes in the future and the design will not break.
        </p>
      </div>

      <div className="fullwidth mt-l text-center pt-s pb-s bg-soft-grey">
        <div className="container-xl auto">
          <img src={`${process.env.PUBLIC_URL}/img/projects/companion/before&after.png`} alt="before & after"/>
          <div className="caption">Before & After. Before, users need to drag the map to show transportaion modes. After, transportation mode is always visible, and with a single tap you can select / change between different modes.</div>
        </div>
      </div>

      <div className="container container-xs mt-l">
        <p>
          We were especially looking to see how users responded to the new approach to change transportation. In general, it was well received. I continued to refine the experience so that the trip journey were clearer.
        </p>
        <p>
          After some final explorations and tweakings I ended up with a very simplified user flow.
        </p>
      </div>

      <div className="fullwidth mt-l text-center pt-s pb-s bg-soft-grey">
        <div className="container-xl auto">
          <img src={`${process.env.PUBLIC_URL}/img/projects/companion/screens.png`} alt="Screens" />
          <div className="caption">Transportation modes user flow.</div>
        </div>
      </div>

      <div className="container container-xs mt-l">
        <div className="label">Wander Mode</div>
        <p className="text-bold">
          Feature with lack of understanding. Is too hidden? Not as obvious?
        </p>
        <p>
          To improve "Wander Mode" feature, I focused on UX Writing and improving UI. The custom icon describing the feature was not descriptive, and terminology was not as clear as it should be. The term "Wander mode" seems to be related to user action, and there is a wide variety of them. "Exercise mode", for example, would be too much limited. So why don’t we try to describe the feature? Maybe associating with time: Time based, Timer… Also we can use UI decisions to inform, adding a descriptive icon and visually changing the row. This proposal was well received, and had positive impact.
        </p>
        <p>
          User research gave the clue of frequent destinations. Home and work were the most frequent places, by a big difference, so I included two new options for this most used places.
        </p>
      </div>

      <div className="fullwidth mt-l text-center pt-s pb-s bg-soft-grey">
        <div className="container-xl auto">
          <img src={`${process.env.PUBLIC_URL}/img/projects/companion/wander-mode.png`} alt="wander mode" />
          <div className="caption">Before & After "Wander Mode".</div>
        </div>
      </div>


      <div className="container container-xs mt-l">
        <div className="label">Adding chocolate <span role="img" aria-label="chocolate">🍫</span></div>
        <p className="text-bold">
          Companions for building trust
        </p>
        <p>
          How can we improve the “safety feeling”? While redesigning the app I had to study all the features, and a lot of questions surrounded me. But the main one was to improve the “trust feeling”. Before the redesign, companions were represented with an initial and placed at the bottom of the screen, I ended up giving people, our Companions, more importance showing their faces, and using familiar UI patterns, to reinforce a sense of safety, trust, and familiarity.
        </p>
      </div>

      <div className="fullwidth mt-l text-center pt-s pb-s bg-soft-grey">
        <div className="container auto">
          <img src={`${process.env.PUBLIC_URL}/img/projects/companion/final-screens.png`} alt="final screens" />
          <div className="caption">The look and feel of the final app redesign.</div>
        </div>
      </div>



      <div className="container container-xs mt-l">
        <div className="label">Key learnings</div>
        <p className="text-bold">
          I learned a lot throughout the redesign process about design best practices. Some of the key takeaways were as follows:
        </p>
        <ul>
          <li><strong>Progressive reveal is better than more options.</strong> Users preferred a more progressive flow of information with fewer options at each stage.</li>
          <li><strong>Naming matters.</strong> Custom terminology such as "Wander Mode" isn’t intuitive for users. Where possible, it’s best to use explanatory, relatable language instead.</li>
          <li><strong>Grouping information helps.</strong> Consolidating transportation modes information into a single icon was a win with users.</li>
          <li><strong>Personalization works.</strong> Members responded well to real photos of their companions and used most frequent places such work and home.</li>
          <li><strong>Design as simplicity.</strong> What can be removed without hurting performance? Simplifying is great as long as it doesn't hurt the performance and objective of the tool/product. Simple is born from complex.</li>
          <li><strong>Universal design principles work.</strong> Everyone needs to Feel Safe, Understand, Achieve, and Experience beauty.</li>
        </ul>
      </div>


      <div className="container container-xs mt-l">
        <p>
          While freelancing across different timezones I had the opportunity to learn a few things. Focusing on Transparency, Documentation, and Communication is the key. <strong>Transparency</strong>. Inform regularly on you current status. <strong>Documentation</strong>. Document all your design decisions, for small and insignificant that it looks, sharing it can have unexpected results. <strong>Communication</strong>. It has extra efforts to explain your work while in different timezones, but regular communication is crucial, we are human after all.
        </p>
        <p>
          All of this explorations were done in <strong>weekly sprints</strong> during very few weeks. At that time Lexie and Jake were building a team in NYC, and felt  that they  need to put design on hold for the time being, and put all of their energy into the product fixing up bugs and tweaking features. 
        </p>
        <p>
          Few weeks later I was watching a new show called “Planet of the Apps” and how big my surprise was when I watched Jake and Lexie pitching for the project.
        </p>
        <br></br>

        <img src={`${process.env.PUBLIC_URL}/img/projects/companion/planetoftheapps.jpg`} alt="Planet of the Apps" />
        <div className="caption">Jake & Lexie on air in “Planet of the Apps”</div>
        <br></br>
        <p>And they were selected! Wish all the best guys!</p>
      </div>





      </div>

    </div>

    <Footer />
    <Banner />

  </div>

  );
}
export default content