import React from "react";
import Footer from '../components/footer';
import Banner from '../content/banner';

const content = () => {
  return (
  <div>
    <div className="project-detail project-mindset">

      <div className="project-detail-title container mt-xl">
        <h1 className="project-title">
          Koa Mindset.<br></br><span>Helping people improve their mood and wellbeing.</span>
        </h1>
      </div>


      <div className="project-detail-content">

        <div className="bg-soft-grey text-center displace-xl mb-m pb-l">
          <img src={`${process.env.PUBLIC_URL}/img/projects/mindset/mindset-3.png`} className="text-center mx-w-80" alt="Koa Mindset"/>
          <div className="caption pt-l">Overview of Koa Mindset after using Design System components</div>
        </div>

        <div className="container container-xs mt-l">
          <h2>A solid foundation</h2>
          <p className="">
            Mindset helps individuals better understand and live well with depression by offering cognitive behavioral therapy-based techniques to complement in-person treatment.          
          </p>
          <p>
            We developed Mindset in collaboration with Massachusetts General Hospital, it offers a cost-effective, CBT-based tool to support patients outside of scheduled sessions.
          </p>
        </div>

        <div className="container container-xs mt-l">
          <div className="label">The impact</div>
          <p className="text-bold">
            Standardized app design by auditing and unifying components, resulting in a cohesive and scalable experience across Koa Health's products.
          </p>
        </div>

        <div className="container container-xs mt-l">
          <div className="label">My Role</div>
          <ul>
            <li>Partnering with PM, engineers, design manager and other designers to set up the app vision, and participate in OKRs</li>
            <li>Contributing to the design roadmap and task prioritization</li>
            <li>Proactively evolving ways of working between PM, engeneering and design</li>
            <li>Designing from conceptualization to launch, and evolving existent features</li>
            <li>Contribute and evolve our Design System</li>
            <li>Working closely with Massachussets General Hospital to gather science based insights for shaping the user experience</li>
            <li>Creating documentation for implementation and QA before every release</li>
            <li>Conceptualizing, partnering researchers, and analysing usability tests</li>
            <li>Analyzing qualitative and quantitative data</li>
          </ul>
          <p className="mt-m">
            From all the features I led design while at Koa Health, without any doubt the one that I consider more challenging -and with more strategic impact- was the <strong>standardization of the app design</strong>.           
          </p>
        </div>

        <div className="container container-xs mt-l">
          <div className="label mb-s">The focus</div>
          <h2>Standardization of the app design</h2>
          <div className="label mt-m">The context, the Why</div>
          <p className="text-bold">Two teams, two products and too many custom solutions</p>
          <p>
            When I first joined Koa Health we had two main user facing products: <span className="bold">Koa Foundations</span> and <span className="bold">Koa Mindset</span>. Each one of them was designed and built for different audiences, with different business goals, and by different teams -and that was quite noticeable.
          </p>
          <p>
            At that moment, our Koa Design System (KDS) was very fresh. A small team of passionate folks working together towards unifying our design language across products and platforms, and building solutions as parts of a greater whole. While the KDS started to grow, <strong>the reality was that both products were still using a great amount of custom solutions</strong> causing experiences to diverge, designers to reinvent and slow down, and engineers to maintain duplicated code.
          </p>
        </div>

        <div className="container container-xs mt-l">
          <div className="label">The Challenge</div>
          <p className="text-bold">
            How do we organize this, so it does its job, costs less and grows more?
          </p>
        </div>



        <div className="container container-xs mt-l">
          <div className="label">The Goal</div>
          <p className="text-bold">To create coherent experiences across products, putting the Design Systems team at the core of everything we build to help make design and development consistent (and faster 💨 ).</p>
          <p>
            As an active contributor to the Design System and core designer in one of the user facing products (Koa Mindset), I started to worry about the big picture behind the scenario we were facing: two products, one underused Design System and too many custom solutions. So began to evangelize about the situation and the work needed in order to get the desired <strong>standardization of our consumer facing products</strong>.
          </p>
        </div>

        <div className="fullwidth text-center mt-l">
            <img className="mx-h-700 br-m" src={`${process.env.PUBLIC_URL}/img/projects/mindset/koa-structure.jpg`} alt="Tribe structure"/>
            <div className="caption mt-m">Koa Health tribe structure and Design System team federated model</div>
        </div>
        

        <div className="container container-xs mt-l">
          <div className="label">Getting to work</div>
          <p className="text-bold">Auditing the products and defining how success should look like</p>
          <p>
            We <strong>started by auditing</strong> many of our designs, both in Foundations and Mindset, for understanding the current state. Laying the flows side by side, we could see where and how the experiences were diverging and where we needed to start aligning.
          </p>
          <div className="fullwidth text-center mt-s">
            <img className="mx-h-700" src={`${process.env.PUBLIC_URL}/img/projects/mindset/mindset-audit.png`} alt="Audit"/>
            <div className="caption mt-m">Mindset custom components audit</div>
        </div>
          <p className="mt-m">
            We <strong>documented</strong> all the custom solutions and worked towards a shared vision defined by our emerging language and shared components.
          </p>

        </div>

        <div className="container container-xs mt-l">
          <div className="label">Creating the Components</div>
          <p className="text-bold">Components as elements of a bigger organism</p>
          <p>
           Instead of relying on individual product apporoaches, we considered our components as elements of a living organism on top of both products. Every component was built with a default state that supported a set of tokens to serve different product needs. 
          </p>
        </div>

        <div className="fullwidth text-center mt-l bg-soft-grey pb-m pt-m">
            <img className="mx-h-700 br-m" src={`${process.env.PUBLIC_URL}/img/projects/mindset/KDS-token.png`} alt="Design Tokens"/>
            <div className="caption mt-m">Design System token structure</div>
        </div>

        
        <div className="container container-xs mt-l">
          <p>
            Together with product designers and the design system lead I worked on identifying diverging patterns and envisioning how we could unify them. This was a huge amount of analysis work that ended up in <strong>solutions that bringed both products one step closer</strong>.
          </p>
          <p>
            As a contributor to the Design System but product designer on the consumer side, I was in charge of the impact this initiative had specially in Koa Mindset. 
          </p>
        </div>

        <div className="fullwidth text-center mt-l">
            <img className="mx-h-700 br-m" src={`${process.env.PUBLIC_URL}/img/projects/mindset/mindset-components.jpg`} alt="Design System update"/>
            <div className="caption mt-m">Example screen of Koa Mindset before and after Design System components update using design tokens</div>
        </div>


        <div className="container container-xs mt-l">
          <div className="label">Adoption Strategy</div>
          <p className="text-bold">Bottom-up approach for implementation</p>
          <p>
            Once we had on place a set of shared components and patterns I partnered the engineering team to design a roadmap for replacing existing custom components with the design system's alternative. Finally, custom resources that were no longer needed would be removed from the original codebase and design library.
          </p>
          <p>
            The <strong>bottom-up implementation strategy</strong> we followed resulted pretty successful. First we approached elements in views, then moved one layer up to view-groups, and finally main root views. 
          </p>
        </div>


        <div className="container container-xs mt-l">
          <div className="label">Results</div>
          <p className="text-bold">A cohesive experience for Koa Health products</p>
          <p>
            We reviewed and unified the majority of the Koa Mindset app, from atoms, to components, and finally patterns, worked on proposals for covering gaps, and reduced the amount of custom solutions needed for achieving an improved experience.
          </p>
          <p>
            This initiative allowed our products to accommodate theming without requiring to refactor the full interface and most important to <strong>maintain a cohesive feel, even while rapidly scaling</strong>.
          </p>
        </div>



        <div className="container container-m mt-l mb-l">
          <div className="circle-wrapper">
          <div className="data-rect">
              <div className="data-num">+150</div>
              <div className="bold mt-s text-center">Deleted custom components and variants</div>
              <p className="small-text text-center">
                A significant proportion of custom UI components and all their variants were deleted and don't need to be maintained anymore  
              </p>
            </div>
            <div className="data-rect">
              <div className="data-num">95%</div>
              <div className="bold mt-s text-center">Shared components</div>
              <p className="small-text text-center">We’ve moved from roughly a 15% of shared components into a 95%. Increasing the interaction pattern cohesiveness between the two products. </p>
            </div>
            <div className="data-rect">
              <div className="data-num">+25%</div>
              <div className="bold mt-s text-center">Product development efficiency</div>
              <p className="small-text text-center">Engineers estimated not hours or days but weeks saved per sprint. Increasing our product development efficiency and efficacy by at least 25% (time and money) per quarter.</p>
            </div>
          </div>
        </div>

        <div className="container container-xs mt-l">
          <div className="label">Lessons learned</div>
            <ul>
              <li>Audit and document. <strong>Breakdown all components and patterns</strong> in early stages, list, document and share. Having a clear audit will save a lot of time on team alignment.</li>
              <li>Have a <strong>clear roadmap</strong> and split the effort to implement the initiative. Define a strategy, in our case the bottom-up approach was really helpful.</li>
              <li><strong>Provide details and context</strong> that is meaningful to the rest of the company. Spread the word, share decks company wide, even prepare workshops.</li>
              <li>Make sure to <strong>review and validate task by task</strong>. Don't wait to QA design untill the end.</li>
            </ul>
            
            <p className="mt-m">
            The migration was possible thanks to all my amazing colleagues at the design system team, plus all the effort on the product side from product designers, brand designers and software engineers.
            </p>
        </div>



      </div>

    </div>

    <Footer />
    <Banner />
  </div>

  );
}
export default content